<template>
  <div id="notfound">
    <Header></Header>

    <div class="contain">
      <div class="banner">
        <img class="bannerImg" src="../assets/404bgc.jpg" />
      </div>
      <div class="page">
        <img src="../assets/404.png" />
        <div class="en">EXCUSE ME</div>
        <div class="ch">很抱歉，您访问的页面不存在！</div>
        <div class="back" @click="toIndex()">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toIndex(){
      this.$router.push({ path: '/' });
    }
  },
};
</script>

<style scoped lang="less">
#notfound {
  width: 100%;
}

.contain {
  width: 100%;
    // margin-top: 2.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.banner {
  width: 100%;
  font-size: 0;
  .bannerImg {
    width: 100%;
  }
}

.page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #206C47;
  line-height: 1;
  img{
      height: 4.28125rem;
  }
  .en {
    font-size: .6875rem;
    margin: 2.0625rem 0 .75rem 0;
  }
  .ch {
    font-size: .75rem;
  }
  .back {
    font-size: .75rem;
    border: 1px solid #206C47;
    padding: .625rem 1.5rem;
    border-radius: 1.875rem;
    margin-top: 1.375rem;
  }
}
</style>