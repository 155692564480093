<template>
  <div id="header">
    <!-- 头部 -->
    <div class="headerBox">
      <div class="header">
        <div class="headerleft">
          <!-- 点击弹出层显示 -->
          <van-cell @click="showPopup" class="vanImg">
            <img class="dots" src="../assets/indexIcon.png" />
          </van-cell>
          <!-- 弹出层 -->
          <van-popup
            v-model="isshow"
            position="left"
            :style="{ height: '100%' }"
          >
            <div class="popup">
              <div class="popuptop">
                <img class="logo" src="../assets/logo.png" />
                <img
                  class="delIcon"
                  @click="showPopup"
                  src="../assets/delIcon.png"
                />
              </div>

              <div class="popupbottom" v-if="tabList.length > 0">
                <div
                  class="popupItem"
                  @click="setNav(1)"
                  :class="navActive == 1 ? 'navItemActive' : ''"
                >
                  <div class="popupImgs">
                    <img
                      class="popupImg"
                      v-if="navActive == 1"
                      src="../assets/102.png"
                    />
                    <img class="popupImg" v-else src="../assets/101.png" />
                  </div>
                  <div
                    class="popupText"
                    :class="navActive == 1 ? 'navActive' : ''"
                  >
                    {{ tabList[0].name }}
                  </div>
                </div>

                <!-- 关于无邪 -->
                <div
                  class="popupItem2"
                  @click="setNav(2)"
                  :class="navActive == 2 ? 'navItemActive' : ''"
                >
                  <div class="popupAbout">
                    <div class="popupImgs">
                      <img
                        class="popupImg"
                        v-if="navActive == 2"
                        src="../assets/202.png"
                      />
                      <img class="popupImg" v-else src="../assets/201.png" />
                    </div>
                    <div
                      class="popupText"
                      :class="navActive == 2 ? 'navActive' : ''"
                    >
                      {{ tabList[1].name }}
                    </div>
                    <img
                      v-if="isshowAbout"
                      class="bottomicon"
                      src="../assets/bottom.png"
                    />
                  </div>
                  <div class="about" v-if="isshowAbout">
                    <div
                      @click.stop="setActive(1)"
                      :class="isActive == 1 ? 'isActive' : ''"
                    >
                      关于我们
                    </div>
                    <div
                      @click.stop="setActive(2)"
                      :class="isActive == 2 ? 'isActive' : ''"
                    >
                      核心技术
                    </div>
                    <div
                      @click.stop="setActive(3)"
                      :class="isActive == 3 ? 'isActive' : ''"
                    >
                      产品理念
                    </div>
                  </div>
                </div>

                <div
                  class="popupItem"
                  @click="setNav(3)"
                  :class="navActive == 3 ? 'navItemActive' : ''"
                >
                  <div class="popupImgs">
                    <img
                      class="popupImg"
                      v-if="navActive == 3"
                      src="../assets/302.png"
                    />
                    <img class="popupImg" v-else src="../assets/301.png" />
                  </div>
                  <div
                    class="popupText"
                    :class="navActive == 3 ? 'navActive' : ''"
                  >
                    {{ tabList[2].name }}
                  </div>
                </div>

                <div
                  class="popupItem"
                  @click="setNav(4)"
                  :class="navActive == 4 ? 'navItemActive' : ''"
                >
                  <div class="popupImgs">
                    <img
                      class="popupImg"
                      v-if="navActive == 4"
                      src="../assets/402.png"
                    />
                    <img class="popupImg" v-else src="../assets/401.png" />
                  </div>
                  <div
                    class="popupText"
                    :class="navActive == 4 ? 'navActive' : ''"
                  >
                    {{ tabList[3].name }}
                  </div>
                </div>

                <div
                  class="popupItem"
                  @click="setNav(5)"
                  :class="navActive == 5 ? 'navItemActive' : ''"
                >
                  <div class="popupImgs">
                    <img
                      class="popupImg"
                      v-if="navActive == 5"
                      src="../assets/502.png"
                    />
                    <img class="popupImg" v-else src="../assets/501.png" />
                  </div>
                  <div
                    class="popupText"
                    :class="navActive == 5 ? 'navActive' : ''"
                  >
                    {{ tabList[4].name }}
                  </div>
                </div>
              </div>
            </div>
          </van-popup>
        </div>

        <!-- logo -->
        <div class="headercenter" @click="toIndex">
          <img src="../assets/logo.png" class="logo" alt="无邪生物" />
        </div>

        <div class="headerright"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { Cell, CellGroup, Popup, Icon } from "vant";
export default {
  props: ["nav"],
  data() {
    return {
      navActive: this.nav,
      logoImg: "",
      isshow: false, //是否显示侧边栏
      tabList: [],
      isshowAbout: false, //是否打开关于无邪
      isActive: 1, //关于无邪（关于我们/核心技术/产品理念高亮）
    };
  },
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
  },
  mounted() {
    this.isActive = JSON.parse(localStorage.getItem("isActive"));

    this.$axios.post("https://api1.pyua.net/index/get_nav").then((response) => {
      // console.log(response.data.arr);
      if (response && response.code == 1) {
        this.tabList = response.data.arr;
      }
    });
  },
  methods: {
    // 是否显示侧边栏
    showPopup() {
      this.isshow = !this.isshow;
      if (this.navActive == 2) {
        this.isshowAbout = true;
      }
    },
    setNav(num) {
      let urls = "";
      if (num == 1) {
        urls = `/`;
      } else if (num == 2) {
        if (this.isshowAbout) {
          this.isActive = JSON.parse(localStorage.getItem("isActive"));
        } else {
          this.isActive = -1;
        }
        this.isshowAbout = !this.isshowAbout;
      } else if (num == 3) {
        urls = `/products`;
      } else if (num == 4) {
        urls = `/news`;
      } else if (num == 5) {
        urls = `/contact`;
      } else {
        urls = `/`;
      }
      if (urls) {
        window.location.href = urls;
      }
    },

    setActive(num) {
      this.navActive = 2;
      this.isActive = num;
      localStorage.setItem("isActive", JSON.stringify(num));
      let urls = "";
      if (num == 1) {
        urls = `/aboutUs`;
      } else if (num == 2) {
        urls = `/coreTechnology`;
      } else if (num == 3) {
        urls = `/productIdea`;
      }
      if (urls) {
        window.location.href = urls;
      }
    },

    // 点击logo回到首页
    toIndex() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="less" scoped>
#home {
  background: #fff;
}

// 头部
.headerBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  background: #fff;
  z-index: 1000;
  border-bottom: 1px solid #ececec;
}

.header {
  padding: 0.40625rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /deep/ .van-cell {
    display: flex;
    align-items: center;
    padding: 0;
    width: auto;
  }
  /deep/ .van-cell__value {
    display: flex;
    align-items: center;
  }
  /deep/ .van-popup {
    background-color: #f9f9f9;
    height: 100vh;
    width: 15.625rem;
  }
}
.headerleft {
  display: flex;
  align-items: center;
  width: 5.6rem;
  .dots {
    height: 1.25rem;
  }
  .popup {
    .popuptop {
      padding: 0.8125rem 0.625rem 0 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 2rem;
        height: 1.6875rem;
      }
      .delIcon {
        width: 1.1875rem;
        height: 1.1875rem;
      }
    }
    .popupbottom {
      margin-top: 3.375rem;
      .popupItem {
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ededed;
      }
      .navItemActive {
        background-color: #fff;
      }
    }
  }
}

.popupImgs {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupImg {
  width: 1.5rem;
  height: 1.5rem;
}
.popupText {
  line-height: 1;
  font-size: 1rem;
  color: #999;
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  // border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.navActive {
  color: #206c47;
  font-weight: 700;
  background-color: #fff;
}

.headercenter {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 2rem;
    height: 1.6875rem;
  }
}

.headerright {
  width: 5.6rem;
}

.popupItem2 {
  // height: 10.125rem;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: column;
}
.popupAbout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  // background-color: pink;
  .bottomicon {
    width: 0.8125rem;
    margin-right: 0.8125rem;
  }
}
.about {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    margin-left: 4.1875rem;
    margin-bottom: 1rem;
    color: #999999;
    font-size: 1rem;
    &:nth-child(1) {
      margin-top: 1rem;
    }
  }
  .isActive {
    color: #206c47;
  }
}
</style>
